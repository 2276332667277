var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"9606d7ef4429cfa5ef32f412dcd3eaf6ff19105d"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";

const dsn = process.env.NEXT_PUBLIC_SENTRY_DSN;

if (dsn) {
  Sentry.init({
    dsn: dsn,
    // Source: https://docs.sentry.io/platforms/javascript/configuration/sampling/
    tracesSampler: ({ name, parentSampled }) => {
      // Do not sample health checks ever
      if (name.includes("healthcheck")) {
        // Drop this transaction, by setting its sample rate to 0%
        return 0;
      }

      // Continue trace decision, if there is any parentSampled information
      if (typeof parentSampled === "boolean") {
        return parentSampled;
      }

      // Else, use default sample rate (replacing tracesSampleRate)
      return 0.2;
    },
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
    environment: process.env.ENVIRONMENT || process.env.NEXT_PUBLIC_ENV || ""
  });
}
