import compact from "lodash/compact";
import pick from "lodash/pick";
import uniq from "lodash/uniq";
import uniqBy from "lodash/uniqBy";
import { max } from "date-fns/max";

import { DateFromApi } from "graphqlApi/customTypes";
import {
  StatAttributions,
  StatIdConfig,
  TopicDashboardDataGroup,
  TopicDashboardSectionConfig,
  TopicDashboardSectionProps,
  TopicDashboardSubsectionConfig,
  TopicDashboardSubSectionData,
  TopicDashboardSubsectionTypeConfig
} from "../elementHelpers/dashboard/types";
import {
  MhcGeographyEnum,
  MhcLocationBaseFragment,
  MhcLocationFragment,
  MhcTimeSeriesGranularityEnum
} from "graphqlApi/types";

import { getSmallestGranularity } from "./kpi/util";
import { CreateLoadedStatDictionary } from "./util";
import { getMaxDateFromAttributions } from "common/util/attributions";
import { getLatestDateFromArray } from "common/util/getLatestDate";

import {
  InvestigateMapAvailableGeographies,
  InvestigateMapLocationReference
} from "common/components/InvestigateMap/InvestigateMap";
import { getLocationName } from "../elementHelpers";
import { fetchChartData } from "./charts/fetchChartData";
import { fetchKpiGroupProps } from "./kpi/fetchKpiGroupProps";
import { fetchBivariateAnalysis } from "./fetchBivariateAnalysis";
import { fetchMapData } from "./fetchMapData";
import { fetchMapDataV2 } from "./fetchMapDataV2";
import {
  fetchStatsForAllSections,
  getStatDictionaryForAnyLocation,
  LoadedLocationStatDictionaryByLocation
} from "./fetchStatsForAllSections";
import { fetchTableData } from "./fetchTableData";

interface Props {
  sections: TopicDashboardSectionConfig[];
  location: MhcLocationFragment;
  locationReference?: InvestigateMapLocationReference;
  mapGeographies?: InvestigateMapAvailableGeographies[];
  locationStatArgs?: CreateLoadedStatDictionary["locationStatArgs"];
}

export const configString = (si: StatIdConfig, configString = false) => {
  if (typeof si === "string") {
    return si;
  } else if (configString === true) {
    return `${si.identifier}${si?.startsOn ? `:${si.startsOn}` : ""}${
      si?.endsOn ? `:${si.endsOn}` : ""
    }`;
  } else {
    return si.identifier;
  }
};

export const getIdentifiersOfStatIdConfigs = (statIdConfigs: StatIdConfig[]): string[] =>
  statIdConfigs.map((si) => configString(si));

export const geographySupportsSection = (
  supportedGeographies: MhcGeographyEnum[] | null | undefined,
  locationGeography: MhcGeographyEnum
) => {
  if (supportedGeographies) {
    return supportedGeographies.includes(locationGeography);
  }
  return true;
};

export const fetchSectionData = async ({
  sections = [],
  location,
  locationReference,
  mapGeographies = [],
  locationStatArgs = {}
}: Props): Promise<TopicDashboardSectionProps[]> => {
  const statIdentifierDictionary: LoadedLocationStatDictionaryByLocation =
    await fetchStatsForAllSections({
      sections,
      locationId: location.id,
      locationGeography: location.geography,
      locationStatArgs
    });

  const getLoadedStats = (
    config:
      | TopicDashboardSubsectionTypeConfig
      | TopicDashboardSubsectionConfig["chartConfigs"]
      | null = null
  ) => {
    let statIdConfigs: StatIdConfig[] | null = null;
    if (config && (config as TopicDashboardSubsectionConfig["chartConfigs"])?.length) {
      statIdConfigs =
        (config as TopicDashboardSubsectionConfig["chartConfigs"])?.flatMap(
          (c) => c.statIdConfigs ?? []
        ) ?? [];
    }
    return pick(
      statIdentifierDictionary,
      getIdentifiersOfStatIdConfigs(
        (statIdConfigs || (config as TopicDashboardSubsectionTypeConfig)?.statIdConfigs) ?? []
      )
    );
  };

  return await Promise.all(
    sections.map(async ({ subSections, id, title, granularity: sectionGranularity }) => {
      let latestDates: DateFromApi[] = [];
      const granularities: MhcTimeSeriesGranularityEnum[] = [];
      const { id: locationId, geography: locationGeography } = location;
      const locationName = getLocationName(location);
      let attributions: StatAttributions = [];
      const locationWithoutGeoJson = pick(location, [
        "__typename",
        "id",
        "name",
        "geography"
      ]) as MhcLocationBaseFragment;
      const subSectionData = await Promise.all(
        subSections.map(
          async ({
            mapConfig = null,
            mapConfigV2 = null,
            kpiGroupConfig = null,
            chartConfigs = null,
            tableConfig = null,
            bivariateAnalysisConfig = null,
            ...props
          }) => {
            const sectionStatIds = uniq(
              [mapConfig, kpiGroupConfig, chartConfigs, tableConfig].flatMap((config) =>
                Object.keys(getLoadedStats(config))
              )
            );
            const sectionData = await Promise.all([
              kpiGroupConfig &&
                (await fetchKpiGroupProps({
                  kpiGroupConfig,
                  locationGeography,
                  locationId,
                  locationName,
                  loadedStats: getLoadedStats(kpiGroupConfig)
                })),
              mapConfig &&
                (await fetchMapData({
                  locationId,
                  geography: MhcGeographyEnum.ZipCode,
                  locationGeography,
                  mapConfig,
                  title: props.title ?? "",
                  loadedStats: getLoadedStats(mapConfig),
                  availableGeographies: mapGeographies,
                  locationReference
                })),
              mapConfigV2 && (await fetchMapDataV2({ ...mapConfigV2, locationStatArgs })),
              chartConfigs &&
                (await fetchChartData({
                  id: props.id,
                  chartConfigs,
                  location: { locationGeography, locationId, locationName },
                  loadedStats: getLoadedStats(chartConfigs),
                  noData: locationId !== "state"
                })),
              tableConfig &&
                (await fetchTableData({
                  tableConfig,
                  location: locationWithoutGeoJson,
                  loadedStats: getLoadedStats(tableConfig)
                })),
              bivariateAnalysisConfig &&
                (await fetchBivariateAnalysis({
                  config: bivariateAnalysisConfig,
                  location,
                  locationGeography: location.geography,
                  locationReference,
                  mapGeographies
                }))
            ]);

            // Data dates
            sectionData.forEach((group: TopicDashboardDataGroup | undefined | null) => {
              if (group) {
                group.hasOwnProperty("latestDate") &&
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  latestDates.push((group as any).latestDate ?? null);
                group.hasOwnProperty("granularity") &&
                  granularities.push(
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    (group as any).granularity ?? MhcTimeSeriesGranularityEnum.Year
                  );
              }
            });

            // Attributions
            const dictionaryOfAllStats = getStatDictionaryForAnyLocation(statIdentifierDictionary);
            const subSectionAttributions: StatAttributions = sectionStatIds.flatMap(
              (id) =>
                (dictionaryOfAllStats[id]?.statIdentifier?.attributions as StatAttributions) ?? []
            );
            const additionalAttributions = sectionData
              .flatMap((section) =>
                section && "attributions" in section
                  ? (section.attributions as StatAttributions) ?? []
                  : null
              )
              .filter((a) => a);
            attributions = [
              ...(attributions ?? []),
              ...subSectionAttributions,
              ...additionalAttributions
            ] as StatAttributions;
            props.attributions = compact(uniqBy(subSectionAttributions, "id"));

            const latestSubSectionDate = latestDates.length
              ? max(compact(latestDates).map((d) => new Date(d)))
              : null;

            const sectionUpdatedDate = getMaxDateFromAttributions(props.attributions) ?? null;

            const [kpiGroupData, mapData, mapDataV2, chartGroupData, tableData, bivariateAnalysis] =
              sectionData;
            const sectionProps: TopicDashboardSubSectionData = { ...props };
            kpiGroupData && (sectionProps.kpiGroup = kpiGroupData);
            mapData && (sectionProps.map = mapData);
            // TODO: fix types
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            mapDataV2 && (sectionProps.mapV2 = mapDataV2 as any);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            chartGroupData && ((sectionProps as any).chartGroup = chartGroupData);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            tableData && ((sectionProps as any).indicatorTable = tableData);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            bivariateAnalysis && ((sectionProps as any).bivariateAnalysis = bivariateAnalysis);
            return {
              ...sectionProps,
              updatedDate: sectionUpdatedDate?.toDateString() ?? null,
              latestDate: latestSubSectionDate?.toDateString() ?? null
            };
          }
        )
      );
      attributions = uniqBy(compact(attributions.flat()), "id") as StatAttributions;
      const updatedDate = getMaxDateFromAttributions(attributions ?? []);
      latestDates = compact(latestDates).filter((d) => d !== "Invalid Date");
      return {
        id,
        title: title ?? "",
        subSections: subSectionData,
        latestDate: getLatestDateFromArray(latestDates) ?? "",
        updatedDate: updatedDate?.toDateString() ?? null,
        granularity:
          getSmallestGranularity(granularities) ??
          sectionGranularity ??
          MhcTimeSeriesGranularityEnum.Year,
        attributions
      };
    })
  );
};
