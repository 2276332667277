import difference from "lodash/difference";

import { IdentifierConfig } from "modules/Topics/util/elementHelpers/dashboard/types";

export const mergeConfigsByIdentifier = (
  configs: IdentifierConfig[],
  locationId: string
): IdentifierConfig[] => {
  const mergedArray: IdentifierConfig[] = configs.reduce(
    (_configs: IdentifierConfig[], currentConfig: IdentifierConfig) => {
      const existingConfig = _configs.find(
        (obj: IdentifierConfig) => obj.identifier === currentConfig.identifier
      );

      if (
        "forLocationId" in currentConfig &&
        currentConfig.forLocationId !== locationId &&
        existingConfig?.forLocationId !== currentConfig.forLocationId
      ) {
        _configs.push(currentConfig);
      } else if (existingConfig && !("forLocationId" in currentConfig)) {
        const diffKeys: (keyof IdentifierConfig)[] = difference(
          Object.keys(currentConfig),
          Object.keys(existingConfig)
        ) as (keyof IdentifierConfig)[];
        diffKeys.forEach((key: keyof IdentifierConfig) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          existingConfig[key] = currentConfig[key];
        });
        for (const key in existingConfig) {
          const _key = key as keyof IdentifierConfig;
          if (currentConfig.hasOwnProperty(_key)) {
            const currentValue = currentConfig[_key];
            const existingValue = existingConfig[_key];

            // Check if the property is a boolean and prefer true values
            if (typeof currentValue === "boolean" && typeof existingValue === "boolean") {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              existingConfig[_key] = existingValue || currentValue;
            } else {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              existingConfig[_key] = currentValue;
            }
          }
        }
      } else {
        // TODO: There needs to be a comparison somwhere in this file that checks if the
        // configurations are different and avoids overwritting the wrong configurations.
        _configs.push(currentConfig);
      }

      return _configs;
    },
    []
  );
  return mergedArray;
};
