// TODO: Refactor to not use styled`
"use client";

import { SyntheticEvent, useCallback } from "react";
import { Tooltip, TooltipProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";

import { sendGaUserInteractionEvent } from "common/util/googleAnalytics";

interface Props extends TooltipProps {
  label?: string;
  lightVariant?: boolean;
}

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.component.textColor,
    boxShadow: theme.shadows[1],
    padding: "20px"
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
    opacity: 1
  }
}));

const MhcTooltip: React.FC<Props> = ({ children, onOpen, lightVariant, label = "", ...props }) => {
  const handleOpen = useCallback(
    (event: SyntheticEvent<Element, Event>) => {
      sendGaUserInteractionEvent({
        category: "Tooltips",
        action: "Viewed",
        label
      });
      onOpen?.(event);
    },
    [onOpen, label]
  );

  if (lightVariant === true) {
    return (
      <LightTooltip {...props} role="tooltip" onOpen={handleOpen}>
        {children}
      </LightTooltip>
    );
  }

  return (
    <Tooltip {...props} role="tooltip" onOpen={handleOpen}>
      {children}
    </Tooltip>
  );
};

export default MhcTooltip;
