import { KeyboardEventHandler, useCallback, useMemo, useRef } from "react";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, IconButton, PopoverOrigin, Stack, Typography } from "@mui/material";
import isNil from "lodash/isNil";
import { bindTrigger, usePopupState } from "material-ui-popup-state/hooks";

import {
  MhcLocationStat,
  MhcLocationStatFragment,
  MhcStatIdentifierFragment,
  MhcTarget,
  MhcTargetFragment
} from "graphqlApi/types";

import { KPI_VALUE_FLEX, KPI_VALUE_WIDTH } from "../configuration";
import { buildStatements, determineProgressIcons } from "./util";
import { visuallyHidden } from "@mui/utils";
import { formatValueByUnit } from "common/util/formatHelpers";
import { getValueOptionsFromSi } from "common/util/formatHelpers/statIdentifierHelpers";
import { sendGaUserInteractionEvent } from "common/util/googleAnalytics";

import TargetIcon from "../../Icons/TargetIcon";
import { KpiIcons } from "../KpiIcons";
import { KpiProps } from "../";
import { TargetPopover } from "./TargetPopover";

export const POPOVER_ORIGIN: PopoverOrigin = {
  vertical: "bottom",
  horizontal: "left"
};

export interface KpiTargetProps {
  currentValue: number;
  dateRange?: Date[] | null;
  hideLongStatement?: boolean;
  isImproving?: boolean;
  kpiFillsContainer?: KpiProps["fillContainer"];
  locationName?: string | null;
  name?: string;
  percentageChange?: string | null;
  statIdentifier: Pick<
    MhcStatIdentifierFragment,
    | "id"
    | "improvement"
    | "precision"
    | "subtitle"
    | "unit"
    | "statType"
    | "name"
    | "isPercent"
    | "ageAdjusted"
  >;
  statSentence?: MhcLocationStat["sentence"];
  target?: MhcTarget | MhcTargetFragment | null;
  targetMet?: boolean;
  locationStat?: MhcLocationStatFragment;
}

export const KpiTarget: React.FC<KpiTargetProps> = ({
  hideLongStatement = false,
  kpiFillsContainer,
  percentageChange,
  target,
  locationStat,
  statIdentifier,
  ...targetProps
}) => {
  const domId = useMemo(() => `target-${statIdentifier.id}`, [statIdentifier.id]);
  const ref = useRef<HTMLDivElement | null>(null);

  const popupState = usePopupState({
    variant: "popover",
    popupId: domId
  });

  let progressIcons = null;
  const { shortStatement, longStatement: _longStatement } = useMemo(
    () => buildStatements({ target, statIdentifier, ...targetProps }),
    [target, targetProps, statIdentifier]
  );

  progressIcons = useMemo(
    () => determineProgressIcons(shortStatement || percentageChange || ""),
    [percentageChange, shortStatement]
  );

  const longStatement = locationStat?.sentence ?? _longStatement;

  const formattedTargetValue = useMemo(() => {
    if (!target) return undefined;
    return formatValueByUnit({
      value: target.value,
      ...getValueOptionsFromSi(statIdentifier)
    });
  }, [target, statIdentifier]);

  const handleKeyboardDown = useCallback<KeyboardEventHandler<HTMLTableRowElement>>(
    (e) => {
      if (e.key !== "Enter") return;
      if (isNil(ref)) return;
      popupState.open(ref.current);
    },
    [popupState]
  );

  return (
    <Stack
      spacing={1}
      onClick={() =>
        sendGaUserInteractionEvent({
          category: "KPI",
          action: "Target click",
          label: statIdentifier?.name ?? "",
          ui_location: window.location.pathname
        })
      }
      onKeyDown={handleKeyboardDown}
    >
      {target && (
        <Box
          {...bindTrigger(popupState)}
          display="flex"
          width="100%"
          tabIndex={0}
          sx={{
            position: "relative",
            minHeight: 40,
            backgroundColor: "brand.light",
            borderRadius: "4px",
            px: 1,
            py: 0.5,
            mt: 1.5,
            mb: 0.5,
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: "brand.light",
            flexWrap: {
              xs: "wrap",
              lg: kpiFillsContainer ? undefined : "wrap",
              cursor: "pointer",
              "&:hover, &:focus": {
                borderColor: "brand.main"
              }
            },
            gap: { xs: 0.75, lg: 0 }
          }}
          aria-haspopup="true"
          ref={ref}
        >
          <Box
            component="span"
            display="flex"
            alignItems="center"
            gap={1}
            sx={{
              flex: { lg: kpiFillsContainer ? KPI_VALUE_FLEX : undefined },
              minWidth: { lg: kpiFillsContainer ? KPI_VALUE_WIDTH : undefined },
              mr: { lg: 2 }
            }}
            tabIndex={0}
          >
            {target && (
              <>
                <TargetIcon fontSize="inherit" sx={{ mt: "2px" }} />
                <Box display="flex" gap={1} alignItems="baseline">
                  <Typography component="span" variant="body2" fontWeight="700">
                    Target
                  </Typography>
                  <Box display="flex" gap={0.5} alignItems="baseline">
                    <Typography component="span" variant="body2">
                      {formattedTargetValue}
                    </Typography>
                    {statIdentifier?.subtitle && (
                      <Typography component="span" variant="body3">
                        {statIdentifier?.subtitle}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </>
            )}
          </Box>
          <Box component="span" display="flex" alignItems="center" tabIndex={0}>
            {progressIcons?.length > 0 ? (
              <Typography fontSize="20px" component="span">
                <KpiIcons icons={progressIcons} />
              </Typography>
            ) : (
              !kpiFillsContainer && <Box width="16px" />
            )}
            <Box display="flex" gap={1} alignItems="baseline">
              <Typography component="span" variant="body2" fontWeight="700">
                Progress
              </Typography>
              <Box display="flex" gap={0.5} alignItems="baseline">
                <Typography component="span" variant="body2">
                  {shortStatement}
                </Typography>
              </Box>
            </Box>
          </Box>
          <IconButton sx={{ ml: "auto" }}>
            <span style={visuallyHidden}>
              {popupState.isOpen ? "Expand less icon" : "Expand more icon"}
            </span>
            {popupState.isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </Box>
      )}

      {target && (
        <TargetPopover
          popupState={popupState}
          tableProps={{
            target: target,
            formattedValue: formattedTargetValue,
            subtitle: statIdentifier.subtitle ?? "",
            progress: shortStatement
          }}
          uiLocation="KPI"
        />
      )}
      {!isNil(longStatement) && !hideLongStatement && (
        <Typography
          data-testid={`${statIdentifier.id}__long-statement`}
          aria-label={`${longStatement} ${locationStat?.needs_attention ?? ""}`}
          tab-index={0}
        >
          {longStatement} {locationStat?.needs_attention ?? ""}
        </Typography>
      )}
    </Stack>
  );
};
