"use client";

import { Components, Theme } from "@mui/material";
import { alpha } from "@mui/material/styles";

import { DEFAULT_SKELETON_ANIMATION } from "layout/configuration";

import { additionalPalette, brandPalette, linkColor } from "./colors";

const { brand } = brandPalette;

export const themeComponentsConfiguration: Components<Theme> = {
  MuiCssBaseline: {
    styleOverrides: {
      body: {
        background: "#fff",
        display: "flex",
        flexDirection: "column",
        flex: "auto",
        "> #__next": {
          position: "relative",
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          flex: "1 1 auto"
        }
      }
    }
  },
  MuiTextField: {
    styleOverrides: {
      root: {
        input: {
          color: linkColor,
          fontWeight: 700,
          "&::placeholder": {
            fontWeight: 500,
            color: additionalPalette.component.textColor
          }
        }
      }
    }
  },
  MuiAccordion: {
    styleOverrides: {
      root: {
        "&.Mui-expanded": {
          marginTop: 0
        }
      }
    }
  },
  MuiAccordionSummary: {
    styleOverrides: {
      root: {
        borderColor: "borders.main",
        "&.Mui-expanded": {
          borderBottom: 0,
          "> div": { marginTop: 16, marginBottom: 16 }
        }
      },
      content: {
        marginTop: 16,
        marginBottom: 16
      }
    }
  },
  MuiAlert: {
    styleOverrides: {
      root: ({ ownerState }) => ({
        ...(ownerState?.variant === "outlined" && { borderWidth: 2 }),
        ...(ownerState?.severity === "success" && {
          "& .MuiAlert-icon": { color: "rgb(89, 151, 91)" }
        }),
        fontWeight: 600
      }),
      standardInfo: {
        background:
          "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #0288D1;",
        border: `2px solid ${brandPalette.brand.main}`
      },
      standardSuccess: {
        background:
          "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #2E7D32;",
        border: "2px solid #2E7D32"
      },
      standardWarning: {
        background:
          "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #ED6C02;",
        border: "2px solid #ED6C02"
      },
      standardError: {
        background:
          "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #D32F2F;",
        border: "2px solid #D32F2F"
      }
    }
  },
  MuiButton: {
    defaultProps: {
      disableElevation: true
    },
    styleOverrides: {
      root: {
        textTransform: "none"
      }
    }
  },
  MuiBreadcrumbs: {
    styleOverrides: {
      separator: {
        margin: "2px"
      }
    }
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        marginBottom: 5,
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "24px",
        color: additionalPalette.component.textColor
      }
    }
  },
  MuiLink: {
    styleOverrides: {
      root: {
        textDecoration: "none",
        "&:hover": {
          textDecoration: "underline"
        }
      }
    }
  },
  MuiSelect: {
    styleOverrides: {
      select: {
        fontWeight: 600,
        color: linkColor,
        padding: "16px"
      }
    }
  },
  MuiSkeleton: {
    defaultProps: {
      animation: DEFAULT_SKELETON_ANIMATION
    },
    styleOverrides: {
      root: {
        borderRadius: "4px"
      }
    }
  },
  MuiSwitch: {
    styleOverrides: {
      root: {
        "& .Mui-checked": {
          color: brandPalette.brand.main,
          "& + .MuiSwitch-track": {
            backgroundColor: brandPalette.brand.main
          }
        }
      }
    }
  },
  MuiTableSortLabel: {
    styleOverrides: {
      root: {
        fontWeight: 600,
        color: "black",
        "&:hover": {
          "> *": {
            color: linkColor,
            fill: linkColor
          }
        },
        "&:focus": {
          "> *": {
            color: brandPalette.brand.main,
            fill: linkColor,
            textDecoration: "underline"
          }
        },
        "&.Mui-active": {
          "> *": {
            color: linkColor,
            fill: linkColor
          },
          "&:hover": {
            "> *": {
              color: linkColor,
              fill: linkColor
            }
          }
        }
      }
    }
  },
  MuiTableRow: {
    styleOverrides: {
      root: ({ ownerState }) => ({
        ...(ownerState.hover && {
          cursor: "pointer",
          transition: "background-color 300ms linear",
          "&:hover": {
            backgroundColor: "transparent !important",
            td: { backgroundColor: alpha(brand.main, 0.05) }
          }
        })
      })
    }
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        fontSize: 14,
        lineHeight: "18px",
        padding: "12px 10px"
      }
    }
  }
};
